import { User, UserRoles } from '../../shared/types/generated';
import { RBACSchema } from './rbac-permissions';
import { RBACPermissions } from './rbac-types';
import { GUEST_ROLE } from '../../shared/constans';

export const checkUserRole = (
  user: User | undefined | null,
  permission: RBACPermissions,
): boolean => {
  let roleName: string = UserRoles.User;
  const roles = user?.roles_on_users?.map((item) => item?.role?.name);
  const subscription = roles?.includes(UserRoles.Suscriptor);

  if (!roles) {
    roleName = GUEST_ROLE;
  }

  console.log({ roles, subscription, permission });

  if (permission === RBACPermissions.CAN_SUBSCRIPTION && subscription)
    return false;

  return !!RBACSchema.find(
    (schema) =>
      schema.Role === roleName && schema.Permissions.includes(permission),
  );
};
